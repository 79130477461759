/* eslint-disable react/no-unescaped-entities, max-len */

import React from 'react';
import { Helmet } from 'react-helmet';
import DefaultLayout from '../containers/default';
import MainWrap from '../components/layout/wrap';
import {
  DocumentTitle,
  Paragraph,
  ParagraphLi,
  SubParagraphLi,
  SubTitle,
  LegalEmailLink,
} from '../components/legal-formatting';

export default () => (
  <DefaultLayout noLogin>
    <Helmet>
      <title>all.health Privacy Policy</title>
      <meta name="description" content="all.health Privacy Policy" />
    </Helmet>
    <MainWrap>
      <DocumentTitle>site privacy policy</DocumentTitle>
      <Paragraph withMargin>Effective as of June 2023</Paragraph>
      <Paragraph>
        At all.health (“
        <b>all.health</b>
        ,” “
        <b>we</b>
        ,” “
        <b>us</b>
        ,” or “
        <b>our</b>
        ”), we are committed to respecting your privacy, and we want
        you to know how we collect, use, and disclose information.
      </Paragraph>
      <Paragraph>
        This Privacy Policy is for our website visitors (the “
        <b>Site Privacy Policy</b>
        ”) and describes our privacy practices with
        respect to any information we collect through this website (the “
        <b>Site</b>
        ”). This Site Privacy Policy describes our privacy practices
        with respect to the information we collect through our website and any
        associated services (collectively, the “
        <b>Site and Site Services</b>
        ”).
      </Paragraph>
      <Paragraph>
        This Policy does not cover any membership-only health services that we
        provide to patients through their provider, or wellness services that we
        provide through an employer, in which case a separate member/patient
        Privacy Policy (the “
        <b>Member Privacy Policy</b>
        ”) governs how we
        collect, use, and disclose information gathered via our sensors or
        wearable band sensors, our mobile applications, our cloud and cloud
        services, our provider portals, and our medical devices and algorithms
        (together, the “
        <b>System</b>
        ”), including any services that the System
        enables (collectively, the “
        <b>System and System Services</b>
        ”). To the
        extent that these other services involve health data, we have a separate
        HIPAA Business Associate Privacy Policy that regulates how we can use,
        process, and store your personal health information.
      </Paragraph>
      <Paragraph>
        [For information about how we collect, use, and disclose information
        gathered via our wearable devices or through our Apps, please see our
        Member Privacy Policy and/or our patient/provider agreement HIPAA
        Business Associate Privacy Policy, available to members and patients.]
      </Paragraph>
      <Paragraph>
        When you submit information to or through the Site and Site Services,
        you consent to the collection, processing, and retention of certain
        submitted information as described in this Site Privacy Policy. Please
        read this Site Privacy Policy before using the Site Services or
        submitting any information to us. By providing personal information to
        us or by using the Services, you acknowledge that you understand and
        agree to the terms of this Site Privacy Policy. This Site Privacy Policy
        is intended for use by residents of the United States only.
      </Paragraph>
      <Paragraph>
        For additional specific information relating to residents of California,
        Colorado, Virginia, Utah, Connecticut, and Nevada please scroll down.
      </Paragraph>
      <SubTitle>1. Information we collect</SubTitle>
      <Paragraph>
        When you use our Site and Site Services, we collect information from you
        that can or could be used to identify you as an individual person (“
        <b>Personal Information</b>
        ”) in a few different ways.
      </Paragraph>
      <Paragraph style={{ fontWeight: 700 }}>
        Information You Voluntarily Provide
      </Paragraph>
      <Paragraph>
        We collect Personal Information that you voluntarily provide when you
        use the Services, such as when you submit your email address to receive
        updates or enter your name for an employment application.
      </Paragraph>
      <Paragraph>
        We may collect Personal Information that you voluntarily provide to us,
        such as when you submit information requests, online forms, or otherwise
        attempt to contact us. If you refuse to provide such information, it may
        affect the quality of our services, but you should still be able to use
        the basic functions of services delivered by this website.
      </Paragraph>
      <Paragraph>
        We typically receive the following categories of Personal Information
        about visitors to our Site:
      </Paragraph>
      <Paragraph isList as="div">
        <ul>
          <ParagraphLi>
            Commercial Information, including records of products or service
            info requested.
          </ParagraphLi>
          <ParagraphLi>
            Contact Details, such as name, address, email, and telephone number
            if these are provided to us by you.
          </ParagraphLi>
          <ParagraphLi>
            Professional Information, such as your current and past employers if
            you apply for a job.
          </ParagraphLi>
          <ParagraphLi>
            Audio, electronic, and visual recordings, such as recordings of
            customer service calls or records of any video conferences or calls.
          </ParagraphLi>
          <ParagraphLi>
            Inferences drawn from any of the information listed above to create
            a profile about an individual reflecting your preferences,
            characteristics, behavior, and attitudes.
          </ParagraphLi>
        </ul>
      </Paragraph>
      <Paragraph>
        However, you may also voluntarily provide other types of Personal
        Information to us during your interactions with us, such as if you
        provide additional information when applying for employment. Should you
        choose to provide us with any additional Personal information, we will
        use such information only as described in this Site Privacy Policy. If
        you submit to us personal information relating to other people, you
        represent that you have the authority to do so, and to permit us to use
        that information in accordance with this Site Privacy Policy.
      </Paragraph>
      <Paragraph style={{ fontWeight: 700 }}>
        Information You Passively Provide
      </Paragraph>
      <Paragraph>
        When you visit our website, we may automatically collect some network
        activity data from your browser or mobile device, such as your IP
        address or unique device identifier, cookies, data regarding which pages
        you visit, and the type of web browser you use to visit our Site. Other
        such information includes your Site browsing history, search history,
        cookie data, referring/exiting URL, clickstream data, time spent on a
        webpage, and other information regarding your interaction with an
        internet website, application, email, educational materials, and so on.
        This type of information may or may not identify, relate to, describe,
        be reasonably capable of being associated with, or be reasonably linked,
        directly or indirectly, with you or your household.
      </Paragraph>
      <Paragraph>
        Our website makes use of “cookies” to automatically collect the above
        information. Cookies are small text files placed on your computer to
        keep track of information about your browsing on the site, some of which
        may be considered your personal information. The utilization of cookies
        allows us to enhance your experience and create tailored web
        applications. You can update cookie preferences at any time by changing
        your browser setting or device setting. Your decision to accept or
        disable cookies is a personal choice, but this website may not work as
        designed if you disable all cookies.
      </Paragraph>
      <Paragraph>
        Your browser may have a setting that allows you to indicate a “Do Not
        Track” preference. Our website does not respond to “Do Not Track”
        signals or other similar mechanisms.
      </Paragraph>
      <Paragraph>
        We may also use Google Analytics, a web analytics service provided by
        Google, Inc. (“Google”), to help collect and analyze certain information
        about you (such as age, gender, and interests). Google Analytics uses
        cookies to analyze use patterns and may collect information about your
        use of the website, including your IP address. More information on
        Google Analytics can be found here:
        {' '}
        <LegalEmailLink
          href="http://www.google.com/policies/privacy/partners/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.google.com/policies/privacy/partners/
        </LegalEmailLink>
        . If you would like to opt-out of having your data used by Google
        Analytics, please use the Google Analytics opt-out available here:
        <LegalEmailLink
          href="https://tools.google.com/dlpage/gaoptout/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://tools.google.com/dlpage/gaoptout/
        </LegalEmailLink>
        . Please note that we make no representations regarding the
        functionality of Google opt-out mechanisms, and further, opting out of
        Google Analytics will not preclude the use of your data by other
        analytics services that we may use.
      </Paragraph>
      <SubTitle>2. How we use the information we collect</SubTitle>
      <Paragraph>
        In addition to the uses listed above, all.health may use the information
        and Personal Information that we collect for the following purposes:
      </Paragraph>
      <Paragraph isList as="div">
        <ul>
          <ParagraphLi>
            Provide information about our products and services to you.
          </ParagraphLi>
          <ParagraphLi>
            Provide support about our products and services to you.
          </ParagraphLi>
          <ParagraphLi>
            Administer, manage, analyze, and improve our products and services.
          </ParagraphLi>
          <ParagraphLi>
            Communicate with you regarding our products and services, our
            policies and terms, and to respond to you when you contact us.
          </ParagraphLi>
          <ParagraphLi>
            Procure vendor/supplier products and services, including to manage
            and satisfy related vendor/supplier contractual obligations.
          </ParagraphLi>
          <ParagraphLi>
            Analyze and better understand your needs, preferences, and
            interests, including by analyzing usage statics.
          </ParagraphLi>
          <ParagraphLi>
            Conduct internal business analysis and market research.
          </ParagraphLi>
          <ParagraphLi>
            For product development and content improvement
          </ParagraphLi>
          <ParagraphLi>
            Marketing and promotional purposes, including by contacting you
            regarding products, services, and topics that may be of interest to
            you.
          </ParagraphLi>
          <ParagraphLi>Engage in joint marketing initiatives.</ParagraphLi>
          <ParagraphLi>
            Comply with legal and regulatory obligations.
          </ParagraphLi>
          <ParagraphLi>
            Administer, provide access to, monitor, and secure our information
            technology systems, websites, applications, databases, and devices,
            including to solve technical problems.
          </ParagraphLi>
          <ParagraphLi>
            Provide access to, monitor, and secure our facilities, equipment,
            and other property.
          </ParagraphLi>
          <ParagraphLi>
            Detect security incidents and other fraudulent activity.
          </ParagraphLi>
          <ParagraphLi>
            Monitor, investigate, and enforce compliance with our policies,
            product, service, or lease terms and conditions, and other legal and
            regulatory requirements.
          </ParagraphLi>
          <ParagraphLi>
            To identify you and your device(s) for any/all purposes identified
            above, including to monitor your use of and interactions with
            products, services, and educational materials for such purposes.
          </ParagraphLi>
        </ul>
      </Paragraph>
      <Paragraph>
        We may also aggregate or de-identify collected personal information and
        analyze that data for statistical or any other purposes.
      </Paragraph>
      <SubTitle>3. How we share your information</SubTitle>
      <Paragraph>
        In addition to the uses listed above, all.health discloses your personal
        information in the following situations:
      </Paragraph>
      <Paragraph isList as="div">
        <ul>
          <ParagraphLi>
            To third parties only with your consent or at your direction.
          </ParagraphLi>
          <ParagraphLi>
            To employees, officials, or departments within our organization or
            among our affiliated organizations for internal use or to provide
            you with requested information and services.
          </ParagraphLi>
          <ParagraphLi>
            To government or judicial authorities to comply with a subpoena,
            court order, governmental inquiry, legal processes, legal
            obligations, or to protect the rights, property, or safety of other
            users or the public.
          </ParagraphLi>
          <ParagraphLi>
            To protect our rights, defend or pursue a legal claim, or
            investigate or prosecute illegal activities.
          </ParagraphLi>
          <ParagraphLi>
            To a successor entity or a purchaser upon a merger, consolidation,
            or other corporate reorganization in which we participate, a sale of
            all or a portion of our assets, or pursuant to a financing
            arrangement. In this situation, such successor entity or purchaser
            will be bound by terms and conditions reasonably similar to this
            policy.
          </ParagraphLi>
          <ParagraphLi>
            To vendors who process your personal information on our behalf or
            who provide us with necessary services under all of the above
            situations.
          </ParagraphLi>
          <ParagraphLi>
            To any other entities when the disclosure of aggregated or
            de-identified data is permitted by applicable laws.
          </ParagraphLi>
        </ul>
      </Paragraph>
      <Paragraph>
        all.health will not disclose Personal Information to third parties
        without your consent, except when necessary to comply with applicable
        laws or legal processes (such as subpoenas, court orders, regulatory
        requests, or investigations), to provide requested cooperation to law
        enforcement authorities, or to protect the rights and property of
        all.health.
      </Paragraph>
      <SubTitle>4. How we protect the information we collect</SubTitle>
      <Paragraph>
        The security of Personal Information is important to us. all.health
        implements reasonable physical and electronic security features to
        promote the confidentiality, integrity, and availability of information
        in our possession or control. Such measures are designed to protect your
        Personal Information from loss, unauthorized access, disclosure,
        alteration, or destruction. However, no method of transmitting or
        securing data is completely secure, and all.health does not guarantee
        the security of information transmitted over the Internet. Specifically,
        email is a non-encrypted form of Internet communications that may be
        accessed or viewed by other Internet users without your knowledge or
        permission while in transit to us. For that reason, in order to protect
        your privacy, please do not use email to communicate information to us
        that you consider confidential. Any information you choose to disclose
        to us is done at your own risk.
      </Paragraph>
      <SubTitle>5. Information from children</SubTitle>
      <Paragraph>
        all.health is concerned about the safety and security of children. Our
        website is intended for a general audience and not targeted to or
        intended for use by children under the age of 16. We do not solicit
        information from children under the age of 16 and children should not
        submit any information, including Personal Information, to all.health or
        this website.
      </Paragraph>
      <SubTitle>
        6. Your right to access and control your personal data
      </SubTitle>
      <Paragraph>Your data belongs to you.</Paragraph>
      <Paragraph>
        We respect your right to be forgotten. If you would like us to erase
        personal data you have shared during your interaction with our Site, use
        the contact us links below, and include “Request to be forgotten,” and
        we will promptly delete your data from our records. Certain
        de-identified information (such as how many requests we receive) will be
        retained, but the personalized information will be forgotten.
      </Paragraph>
      <Paragraph>
        We respect your right to access data and to download the data you have
        shared with us. If you wish to obtain a copy of your data, use the
        “Contact Us” links below, and include “Request for Copy of Data.” We
        will provide you with the necessary information and assistance to
        fulfill your request. Some data is collected and averaged, such as steps
        per day. We do not save the raw data and cannot provide this to you.
      </Paragraph>
      <Paragraph>
        If you do not wish to receive information from us, use the “Contact Us”
        links below, and include “Unsubscribe from Communication,” or you can
        click the unsubscribe link in any email. We will provide you with the
        necessary information and assistance to fulfill your request.
      </Paragraph>
      <SubTitle>7. Contact us</SubTitle>
      <Paragraph>
        If you would like to contact us about this Site Privacy Policy or our
        use of your Personal Information, please contact us as listed below. If
        by email or mail, please include a subject line, such as
      </Paragraph>
      <Paragraph isList as="div">
        <ul>
          <ParagraphLi>“Unsubscribe from Communication,”</ParagraphLi>
          <ParagraphLi>“Request for Copy of Data,”</ParagraphLi>
          <ParagraphLi>“Right to Be Forgotten,”</ParagraphLi>
          <ParagraphLi>“Complaint or Compliment,”</ParagraphLi>
          <ParagraphLi>“Technical Support Question,”</ParagraphLi>
          <ParagraphLi>“Please contact me,”</ParagraphLi>
          <ParagraphLi>“Privacy Question,” or,</ParagraphLi>
          <ParagraphLi>“Other Questions.”</ParagraphLi>
        </ul>
      </Paragraph>
      <Paragraph>Contact us at:</Paragraph>
      <Paragraph>
        <u>By mail:</u>
        <br />
        all.health, Inc.
        <br />
        Attn: Legal Department (re: Site Privacy Policy)
        <br />
        200 Kansas St, #205
        <br />
        San Francisco, California 94103
        <br />
        U.S.A.
      </Paragraph>
      <Paragraph>
        <u>By e-mail:</u>
        <br />
        e:
        {' '}
        <LegalEmailLink href="mailto:support&#064;all.health">
          support&#064;all.health
        </LegalEmailLink>
      </Paragraph>
      <Paragraph>
        <u>By telephone:</u>
        <br />
        t:
        {' '}
        <LegalEmailLink href="tel:1-415-689-3018">
          1.415.689.3018
        </LegalEmailLink>
      </Paragraph>

      <DocumentTitle style={{ paddingTop: '72px' }}>
        supplemental notice
      </DocumentTitle>
      <Paragraph style={{ fontWeight: 700, textAlign: 'center' }}>
        Supplemental Website Privacy Notice for California, Colorado, Virginia,
        Utah, & Connecticut Consumers
      </Paragraph>
      <Paragraph withMargin>Effective as of June 2023</Paragraph>
      <Paragraph>
        This Supplemental Website Privacy Notice (“Supplemental Notice”) applies
        only to information collected about California, Colorado, Virginia,
        Utah, and Connecticut consumers. It provides information required under
        the California Consumer Privacy Act of 2018 and California Privacy
        Rights Act of 2020 and their implementing regulations (collectively, the
        “CCPA”), the Colorado Privacy Act of 2021 (the “CPA”), the Virginia
        Consumer Data Protection Act of 2021 (the “VCDPA”), the Utah Consumer
        Privacy Act of 2022 (the “UCPA”), and the Connecticut Data Privacy Act
        of 2022 (“CDPA”). We also provide a brief paragraph regarding
        information collected about Nevada consumers under the heading “Privacy
        Notice for Nevada Residents” at the end of this Supplemental Notice. The
        other portions of this Supplemental Notice do not apply to Nevada
        consumers.
      </Paragraph>
      <Paragraph>
        This Supplemental Notice describes all.health’s (“
        <b>all.health</b>
        ,” “
        <b>we</b>
        ,” “
        <b>us</b>
        ,” “
        <b>our</b>
        ”) practices regarding the
        collection, use, and disclosure of Personal Information and provides
        instructions for submitting data subject requests. This Supplemental
        Notice is parallel in scope to our Site Privacy Policy. For information
        about how we collect, use and disclose information gathered via our
        wearable devices or through our Apps, please see our HIPAA Business
        Associate Privacy Policy. Some portions of this Supplemental Notice
        apply only to consumers of particular states. In those instances, we
        have indicated that such language applies only to those consumers.
      </Paragraph>
      <Paragraph>
        Please also note that this Supplemental Notice does not address our
        Collection and processing of Personal Information from employees, job
        applicants, other individuals with whom we interact in an
        employment-related context, or business contacts.
      </Paragraph>
      <Paragraph style={{ fontWeight: 700 }}>A. Definitions</Paragraph>
      <Paragraph isList as="div">
        <ul>
          <ParagraphLi>
            “
            <u>Personal Information</u>
            ” means information that identifies,
            relates to, describes, is reasonably capable of being associated
            with, or could reasonably be linked, directly or indirectly, with a
            particular consumer or household. Personal Information includes
            “personal data” as that term is defined in the CPA, VCDPA, UCPA, and
            CDPA. Personal Information also includes “Sensitive Personal
            Information,” as defined below, except where otherwise noted.
          </ParagraphLi>
          <ParagraphLi>
            “
            <u>Sensitive Personal Information</u>
            ” means Personal Information
            that reveals a consumer’s social security, driver’s license, state
            identification card, or passport number; account log-in, financial
            account number, debit card number, or credit card number in
            combination with any required security or access code, password, or
            credentials allowing access to an account; precise geolocation;
            racial or ethnic origin, religious beliefs, or union membership;
            contents of email or text messages; and genetic data. Sensitive
            Personal Information also includes processing of biometric
            information for the purpose of uniquely identifying a consumer and
            Personal Information collected and analyzed concerning a consumer’s
            health, sex life, or sexual orientation. Sensitive Personal
            Information also includes “sensitive data” as that term is defined
            in the CPA, VCDPA, UCPA, and CDPA.
          </ParagraphLi>
          <ParagraphLi>
            “
            <u>Third Party</u>
            ” has the meanings afforded to it in the CCPA,
            CPA, VCDPA, UCPA, and CDPA.
          </ParagraphLi>
          <ParagraphLi>
            “
            <u>Vendor</u>
            ” means a service provider, contractor, or processor
            as those terms are defined in the CCPA, CPA, VCDPA, UCPA, and CDPA.
          </ParagraphLi>
        </ul>
      </Paragraph>
      <Paragraph>
        To the extent other terms used in this Supplemental Notice are defined
        terms under the CCPA, CPA, VCDPA, UCPA, or CDPA they shall have the
        meanings afforded to them in those statutes, whether or not capitalized
        herein. As there are some variations between such definitions in each of
        the five statutes, the definitions applicable to you are those provided
        in the statute for the state in which you are a consumer. For example,
        if you are a Virginia consumer, terms used in this Supplemental Notice
        that are defined terms in the VCDPA shall have the meanings afforded to
        them in the VCDPA as this Supplemental Notice applies to you.
      </Paragraph>
      <Paragraph style={{ fontWeight: 700 }}>
        B. Collection & Processing of Personal Information
      </Paragraph>
      <Paragraph>
        We, and our Vendors, collect the following categories of Personal
        Information about consumers. We also have collected and processed the
        following categories of Personal Information about consumers in the
        preceding 12 months:
      </Paragraph>
      <Paragraph as="div">
        <ul style={{ listStyle: 'none', counterReset: 'item' }}>
          <SubParagraphLi>Identifiers, such as name or address;</SubParagraphLi>
          <SubParagraphLi>
            Contact information, including phone number, address, email address;
          </SubParagraphLi>
          <SubParagraphLi>
            Characteristics of protected classifications under state or federal
            law, such as gender;
          </SubParagraphLi>
          <SubParagraphLi>
            Internet or other electronic network activity information, such as
            browsing history and interactions with our website;
          </SubParagraphLi>
          <SubParagraphLi>
            Geolocation data, such as device location; and
          </SubParagraphLi>
          <SubParagraphLi>
            Professional or employment-related information, such as if you are
            requesting more information or are applying for a job.
          </SubParagraphLi>
        </ul>
      </Paragraph>
      <Paragraph>
        <b>Retention of Personal Information.</b>
        {' '}
        We retain each of the
        categories of Personal Information listed in Section B for the period
        reasonably necessary to provide goods and services to you and for the
        period reasonably necessary to support our business operational purposes
        listed in Section E. Most information is retained for 3 years or less,
        however certain information, such as protected health information data,
        have separate legal rules that require us to retain the health records
        for at least seven years.
      </Paragraph>
      <Paragraph>
        When assessing the data retention period, we take into account the
        amount, nature, and sensitivity of the information, the potential risk
        of harm from unauthorized use or disclosure of the information, the
        purposes for which we process the data and whether we can achieve those
        purposes through other means, and the applicable legal requirements.
      </Paragraph>
      <Paragraph style={{ fontWeight: 700 }}>
        C. Categories of Personal Information We Disclose to Vendors & Third
        Parties
      </Paragraph>
      <Paragraph>
        We do not sell your personal information. We only disclose or share
        information when it is required to provide support or services to you.
      </Paragraph>
      <Paragraph>
        In the past twelve months, we have disclosed the following categories of
        Personal Information to Vendors and Third Parties for a business
        purpose:
      </Paragraph>
      <Paragraph as="div">
        <ul style={{ listStyle: 'none', counterReset: 'item' }}>
          <SubParagraphLi>
            Identifiers, such as name, address, or birthdate (such as when
            providing information to your health provider);
          </SubParagraphLi>
          <SubParagraphLi>
            Contact information, including phone number, address, email address;
          </SubParagraphLi>
          <SubParagraphLi>
            Characteristics of protected classifications under state or federal
            law, such as gender;
          </SubParagraphLi>
          <SubParagraphLi>
            Internet or other electronic network activity information, such as
            browsing history and interactions with our websites;
          </SubParagraphLi>
          <SubParagraphLi>
            Geolocation data, such as device location; and
          </SubParagraphLi>
          <SubParagraphLi>
            Professional or employment-related information, such as when
            requesting a demonstration of our product, or when applying for a
            job.
          </SubParagraphLi>
        </ul>
      </Paragraph>
      <Paragraph>
        <b>Disclosure for California Consumers:</b>
        {' '}
        We will not sell or share
        any of the categories of Personal Information we collect about you, and
        we have not sold or shared Personal Information about California
        consumers in the past twelve months. We do not have actual knowledge
        that we sell or share Personal Information of California consumers under
        16 years of age. For purposes of the CCPA, a “sale” is the disclosure of
        Personal Information to a Third Party for monetary or other valuable
        consideration, and a “share” is the disclosure of Personal Information
        to a Third Party for cross-context behavioral advertising, whether or
        not for monetary or other valuable consideration.
      </Paragraph>
      <Paragraph>
        <b>
          Disclosure for Colorado, Virginia, Utah, and Connecticut Consumers:
        </b>
        {' '}
        We do not sell or share Personal Information to Third Parties or process
        Personal Information for purposes of targeted advertising, as the terms
        “sell,” “share,” “process,” and “targeted advertising” are defined in
        the CPA, VCDPA, UCPA, and CDPA.
      </Paragraph>
      <Paragraph style={{ fontWeight: 700 }}>
        D. Sources from Which We Collect Personal Information
      </Paragraph>
      <Paragraph>
        We collect Personal Information directly from California, Colorado,
        Virginia, Utah, and Connecticut consumers via our website, as well as
        from joint marketing partners, public databases, providers of
        demographic data, publications, professional organizations, social media
        platforms, people with whom you are connected on social media platforms,
        and Vendors and Third Parties when they share the information with us.
      </Paragraph>
      <Paragraph style={{ fontWeight: 700 }}>
        E. Purposes for Processing & Disclosing Personal Information
      </Paragraph>
      <Paragraph>
        We, and our Vendors, collect, process, and disclose the Personal
        Information (excluding Sensitive Personal Information) described in this
        Supplemental Notice to:
      </Paragraph>
      <Paragraph isList as="div">
        <ul>
          <ParagraphLi>Operate, manage, and maintain our business;</ParagraphLi>
          <ParagraphLi>Confirm consumer identities;</ParagraphLi>
          <ParagraphLi>Manage client relationships;</ParagraphLi>
          <ParagraphLi>Provide client services;</ParagraphLi>
          <ParagraphLi>
            Provide, develop, improve, and maintain our services;
          </ParagraphLi>
          <ParagraphLi>Record and maintain client instructions;</ParagraphLi>
          <ParagraphLi>Process payment transactions;</ParagraphLi>
          <ParagraphLi>
            Promote client or business-development events and programming;
          </ParagraphLi>
          <ParagraphLi>
            Direct marketing, including newsletters and client alerts;
          </ParagraphLi>
          <ParagraphLi>
            Better understand the market for our existing services, and
            potential new services, and adjust our research, development, and
            marketing strategies accordingly;
          </ParagraphLi>
          <ParagraphLi>
            Conduct research, analytics, and data analysis;
          </ParagraphLi>
          <ParagraphLi>Monitor and improve website functionality;</ParagraphLi>
          <ParagraphLi>Maintain our facilities and infrastructure;</ParagraphLi>
          <ParagraphLi>
            Protect against security threats and misuse of all.health websites;
          </ParagraphLi>
          <ParagraphLi>
            Conduct risk and security controls and monitoring;
          </ParagraphLi>
          <ParagraphLi>Detect and prevent fraud;</ParagraphLi>
          <ParagraphLi>
            Perform accounting, audit, and other internal functions, such as
            internal investigations;
          </ParagraphLi>
          <ParagraphLi>Process payment transactions;</ParagraphLi>
          <ParagraphLi>Maintain records;</ParagraphLi>
          <ParagraphLi>
            Otherwise accomplish our business purposes and objectives.
          </ParagraphLi>
        </ul>
      </Paragraph>
      <Paragraph>
        In addition to the purposes identified above, all.health may use and
        disclose any and all Applicant Personal Information that we Collect as
        necessary or appropriate to:
      </Paragraph>
      <Paragraph isList as="div">
        <ul>
          <ParagraphLi>
            Comply with laws and regulations, including, without limitation,
            applicable tax, health and safety, anti-discrimination, immigration,
            labor and employment, and social welfare laws;
          </ParagraphLi>
          <ParagraphLi>
            Monitor, investigate, and enforce compliance with and potential
            breaches of all.health policies and procedures and legal and
            regulatory requirements;
          </ParagraphLi>
          <ParagraphLi>
            Comply with civil, criminal, judicial, or regulatory inquiries,
            investigations, subpoenas, or summons; and
          </ParagraphLi>
          <ParagraphLi>
            Exercise or defend the legal rights of all.health and its employees,
            affiliates, customers, contractors, and agents.
          </ParagraphLi>
        </ul>
      </Paragraph>
      <Paragraph>
        We, and our Vendors, collect and process the Sensitive Personal
        Information described in this Supplemental Notice only for the below
        purposes that are authorized by the CCPA and its implementing
        regulations:
      </Paragraph>
      <Paragraph isList as="div">
        <ul>
          <ParagraphLi>
            Performing the services or providing the goods reasonably expected
            by an average consumer who requests those goods or services;
          </ParagraphLi>
          <ParagraphLi>
            Ensuring security and integrity to the extent the use of the
            consumer's Personal Information is reasonably necessary and
            proportionate for these purposes;
          </ParagraphLi>
          <ParagraphLi>
            Preventing, detecting, and investigating security incidents that
            compromise the availability, authenticity, integrity, or
            confidentiality of stored or transmitted Personal Information;
          </ParagraphLi>
          <ParagraphLi>
            Resisting malicious, deceptive, fraudulent, or illegal actions
            directed at the business and prosecuting those responsible for those
            actions;
          </ParagraphLi>
          <ParagraphLi>
            Ensuring the physical safety of natural persons;
          </ParagraphLi>
          <ParagraphLi>
            Performing services on our behalf, including maintaining or
            servicing accounts, providing customer service, processing or
            fulfilling orders and transactions, verifying customer information,
            processing payments, providing financing, providing analytic
            services, providing storage, or providing similar services on our
            behalf;
          </ParagraphLi>
          <ParagraphLi>
            Undertaking activities to verify or maintain the quality or safety
            of a service or device that is owned, manufactured, manufactured
            for, or controlled by us, and to improve, upgrade, or enhance the
            service or device that is owned, manufactured, manufactured for, or
            controlled by us; and
          </ParagraphLi>
          <ParagraphLi>
            Collecting or processing Sensitive Personal Information where such
            collection or processing is not for the purpose of inferring
            characteristics about a consumer.
          </ParagraphLi>
        </ul>
      </Paragraph>
      <Paragraph style={{ fontWeight: 700 }}>
        F. Categories of Entities to Whom We Disclose Personal Information
      </Paragraph>
      <Paragraph isList as="div">
        <ul>
          <ParagraphLi>
            <b>Affiliates & Vendors.</b>
            {' '}
            We may disclose your Personal
            Information to our affiliates and Vendors for the purposes described
            in Section E of this Supplemental Notice. Our Vendors provide us
            with services for our websites, as well as other products and
            services, such as web hosting, data analysis, payment processing,
            customer service, infrastructure provision, technology services,
            email delivery services, credit card processing, legal services, and
            other similar services. We grant our Vendors access to Personal
            Information only to the extent needed for them to perform their
            functions, and require them to protect the confidentiality and
            security of such information.
          </ParagraphLi>
          <ParagraphLi>
            <Paragraph>
              <b>Third Parties.</b>
              {' '}
              For each category of Personal Information
              identified in Section C, we disclose such Personal Information to
              the following categories of Third Parties:
            </Paragraph>
            <Paragraph isList as="div">
              <ul>
                <ParagraphLi>
                  <b>At Your Direction.</b>
                  {' '}
                  We may disclose your Personal
                  Information to any Third Party with your consent or at your
                  direction.
                </ParagraphLi>
                <ParagraphLi>
                  <b>Business Transfers or Assignments.</b>
                  {' '}
                  We may disclose your
                  Personal Information to other entities as reasonably necessary
                  to facilitate a merger, sale, joint venture or collaboration,
                  assignment, transfer, or other disposition of all or any
                  portion of our business, assets, or stock (including in
                  connection with any bankruptcy or similar proceedings).
                </ParagraphLi>
                <ParagraphLi>
                  <b>Legal and Regulatory.</b>
                  {' '}
                  We may disclose your Personal
                  Information to government authorities, including regulatory
                  agencies and courts, as reasonably necessary for our business
                  operational purposes, to assert and defend legal claims, and
                  otherwise as permitted or required by law.
                </ParagraphLi>
              </ul>
            </Paragraph>
          </ParagraphLi>
        </ul>
      </Paragraph>
      <Paragraph style={{ fontWeight: 700 }}>G. Data Subject Rights</Paragraph>
      <Paragraph isList as="div">
        <ul>
          <ParagraphLi>
            <Paragraph>
              <b>Exercising Data Subject Rights.</b>
              {' '}
              California, Colorado,
              Virginia, Utah, and Connecticut consumers have certain rights with
              respect to the collection and use of their Personal Information.
              Those rights vary by state. As required by the CCPA, we provide
              detailed information below regarding the data subject rights
              available to California consumers. Colorado, Virginia, Utah, and
              Connecticut consumers have similar rights and can find more detail
              by referencing the CPA, VCDPA, UCPA, or CDPA, as applicable.
            </Paragraph>
            <Paragraph>
              You may exercise the data subject rights applicable to you under
              the CCPA, CPA, VCDPA, UCPA, or CDPA by contacting us under Contact
              Us in the Site Privacy Policy. Consumers in some states, including
              California, may also authorize an agent to make data subject
              requests on their behalf via the above-listed methods.
            </Paragraph>
          </ParagraphLi>
          <ParagraphLi>
            <b>Verification of Data Subject Requests.</b>
            {' '}
            We may ask you to
            provide information that will enable us to verify your identity in
            order to comply with your data subject request. In particular, when
            a California consumer authorizes an agent to make a request on their
            behalf, we may require the agent to provide proof of signed
            permission from the consumer to submit the request, or we may
            require the consumer to verify their own identity to us or confirm
            with us that they provided the agent with permission to submit the
            request. In some instances, we may decline to honor your request if
            an exception applies under applicable law. We will respond to your
            request consistent with applicable law.
          </ParagraphLi>
          <ParagraphLi>
            <b>Non-Discrimination.</b>
            {' '}
            We will not discriminate against you for
            exercising your data subject rights. For example, we will not deny
            goods or services to you, or charge you different prices or rates,
            or provide a different level of quality for products or services as
            a result of you exercising your data subject rights.
          </ParagraphLi>
          <ParagraphLi>
            <b>Appeals [for Virginia, Colorado, and Connecticut Consumers]. </b>
            Virginia, Colorado, and Connecticut consumers have the right to
            appeal our decisions on their data subject requests. This section
            does not apply to California or Utah consumers.] To appeal our
            decision on your data subject requests, you may contact our Privacy
            Office at the address and email listed under Contact Us in the Site
            Privacy Policy. Please enclose a copy of or otherwise specifically
            reference our decision on your data subject request, so that we may
            adequately address your appeal. We will respond to your appeal in
            accordance with applicable law.
          </ParagraphLi>
          <ParagraphLi>
            <Paragraph>
              <b>Data Subject Rights Disclosure for California Consumers:</b>
              {' '}
              California consumers have the following rights regarding our
              collection and use of their Personal Information, subject to
              certain exceptions.
            </Paragraph>
            <Paragraph isList as="div">
              <ul>
                <ParagraphLi>
                  <Paragraph>
                    <b>Right to Receive Information on Privacy Practices:</b>
                    {' '}
                    You have the right to receive the following information at
                    or before the point of collection:
                  </Paragraph>
                  <Paragraph isList as="div">
                    <ul>
                      <ParagraphLi>
                        The categories of Personal Information to be collected;
                      </ParagraphLi>
                      <ParagraphLi>
                        The purposes for which the categories of Personal
                        Information are collected or used;
                      </ParagraphLi>
                      <ParagraphLi>
                        Whether or not that Personal Information is sold or
                        shared;
                      </ParagraphLi>
                      <ParagraphLi>
                        If the business collects Sensitive Personal Information,
                        the categories of Sensitive Personal Information to be
                        collected, the purposes for which it is collected or
                        used, and whether that information is sold or shared;
                        and
                      </ParagraphLi>
                      <ParagraphLi>
                        The length of time the business intends to retain each
                        category of Personal Information, or if that is not
                        possible, the criteria used to determine that period.
                      </ParagraphLi>
                    </ul>
                  </Paragraph>
                  <Paragraph>
                    We have provided such information in this Supplemental
                    Notice, and you may request further information about our
                    privacy practices by contacting us as at the contact
                    information provided above.
                  </Paragraph>
                </ParagraphLi>
                <ParagraphLi>
                  <b>Right to Deletion:</b>
                  {' '}
                  You may request that we delete any
                  Personal Information about you we that we collected from you.
                </ParagraphLi>
                <ParagraphLi>
                  <b>Right to Correction:</b>
                  {' '}
                  You may request that we correct
                  any inaccurate Personal Information we maintain about you.
                </ParagraphLi>
                <ParagraphLi>
                  <Paragraph>
                    <b>Right to Know:</b>
                    {' '}
                    You may request that we provide you
                    with the following information about how we have handled
                    your Personal Information:
                  </Paragraph>
                  <Paragraph isList as="div">
                    <ul>
                      <ParagraphLi>
                        The categories of Personal Information we collected
                        about you;
                      </ParagraphLi>
                      <ParagraphLi>
                        The categories of sources from which we collected such
                        Personal Information;
                      </ParagraphLi>
                      <ParagraphLi>
                        The business or commercial purpose for collecting,
                        selling, or sharing Personal Information about you;
                      </ParagraphLi>
                      <ParagraphLi>
                        The categories of Third Parties to whom we disclose such
                        Personal Information; and
                      </ParagraphLi>
                      <ParagraphLi>
                        The specific pieces of Personal Information we have
                        collected about you.
                      </ParagraphLi>
                    </ul>
                  </Paragraph>
                </ParagraphLi>
                <ParagraphLi>
                  <Paragraph>
                    <b>
                      Right to Receive Information About Onward Disclosures:
                    </b>
                    {' '}
                    You may request that we disclose to you:
                  </Paragraph>
                  <Paragraph isList as="div">
                    <ul>
                      <ParagraphLi>
                        The categories of Personal Information that we have
                        collected about you;
                      </ParagraphLi>
                      <ParagraphLi>
                        The categories of Personal Information that we have sold
                        or shared about you and the categories of Third Parties
                        to whom the Personal Information was sold or shared; and
                      </ParagraphLi>
                      <ParagraphLi>
                        The categories of Personal Information we have disclosed
                        about you for a business purpose and the categories of
                        persons to whom it was disclosed for a business purpose.
                      </ParagraphLi>
                    </ul>
                  </Paragraph>
                </ParagraphLi>
                <ParagraphLi>
                  <b>Right to Non-Discrimination:</b>
                  {' '}
                  You have the right not to
                  be discriminated against for exercising your data subject
                  rights. We will not discriminate against you for exercising
                  your data subject rights.
                </ParagraphLi>
                <ParagraphLi>
                  <b>
                    Rights to Opt-Out of the Sale and Sharing of Your Personal
                    Information and to Limit the Use of Your Sensitive Personal
                    Information:
                  </b>
                  {' '}
                  You have the right to opt-out of the sale and sharing of your
                  Personal Information. You also have the right to limit the use
                  of your Sensitive Personal Information to the purposes
                  authorized by the CCPA. We will not sell or share any of the
                  categories of Personal Information we Collect about you, and
                  we have not sold or shared Personal Information in the past
                  twelve months. Further, we do not use or disclose Sensitive
                  Personal Information for purposes beyond those authorized by
                  the CCPA.
                </ParagraphLi>
              </ul>
            </Paragraph>
          </ParagraphLi>
          <ParagraphLi>
            <b>Opt-Out Preference Signals.</b>
            {' '}
            We do not sell or share Personal
            Information, or use or disclose Sensitive Personal Information for
            purposes other than those authorized by the CCPA and its
            implementing regulations, as listed in Section E. Accordingly, we do
            not process opt-out preference signals. If we process opt-out
            preference signals in the future, we will update this policy to
            provide details about how we do so.
          </ParagraphLi>
        </ul>
      </Paragraph>
      <Paragraph style={{ fontWeight: 700 }}>H. Other Disclosures</Paragraph>
      <Paragraph isList as="div">
        <ul>
          <ParagraphLi>
            <b>California Residents Under Age 18.</b>
            {' '}
            If you are a resident of
            California under the age of 18 and a registered user of our website,
            you may ask us to remove content or data that you have posted to the
            website by writing to us as specified under Contact Us in the Site
            Privacy Policy. Please note that your request does not ensure
            complete or comprehensive removal of the content or data, as, for
            example, some of your content or data may have been reposted by
            another user.
          </ParagraphLi>
          <ParagraphLi>
            <b>Disclosure About Direct Marketing for California Residents.</b>
            California Civil Code § 1798.83 permits California residents to
            annually request certain information regarding our disclosure of
            Personal Information to other entities for their direct marketing
            purposes in the preceding calendar year. We not distribute your
            Personal Information to other entities for their own direct
            marketing purposes.
          </ParagraphLi>
          <ParagraphLi>
            <b>Financial Incentives for California Consumers.</b>
            {' '}
            Under
            California law, we do not provide financial incentives to California
            consumers who allow us to collect, retain, sell, or share their
            Personal Information. We will describe such programs to you if and
            when we offer them to you.
          </ParagraphLi>
          <ParagraphLi>
            <b>Financial Incentives for California Consumers.</b>
            {' '}
            Under
            California law, we may provide financial incentives to California
            consumers who allow us to collect, retain, sell, or share their
            Personal Information. We will describe such programs to you when we
            offer them to you.
          </ParagraphLi>
          <ParagraphLi>
            <b>Privacy Notice for Nevada Residents.</b>
            {' '}
            We do not sell Covered
            Information as defined under Nevada law, and we generally do not
            disclose or share personal information as defined under Nevada law
            for commercial purposes. Under Nevada law, you have the right to
            direct us to not sell or license your personal information to third
            parties. To exercise this right, if applicable, you or your
            authorized representative may contact our Privacy Office at the
            address listed in our Site Privacy Policy.
          </ParagraphLi>
          <ParagraphLi>
            <b>Changes to our Supplemental Notice.</b>
            {' '}
            We reserve the right to
            amend this Supplemental Notice at our discretion and at any time.
            When we make material changes to this Supplemental Notice, we will
            notify you by posting an updated Supplemental Notice on our website
            and listing the effective date of such updates.
          </ParagraphLi>
          <ParagraphLi>
            <b>Contacting Us.</b>
            {' '}
            If you have any questions, comments, requests,
            or concerns related to this Supplemental Notice, all.health’s
            information practices, or how to access this policy in another
            format, please see the “Contact Us” section of the Site Privacy
            Policy.
          </ParagraphLi>
        </ul>
      </Paragraph>
    </MainWrap>
  </DefaultLayout>
);
